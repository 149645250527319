<template>
  <div class="site-container page-service-related">
    <ab-header></ab-header>

    <div class="site-banner">
      <img src="@/assets/img/website/banner-service-related.png" />
    </div>

    <div class="site-module">
      <div class="sm-hd">设备及特种物流--精密设备运输</div>
      <div class="sm-bd related-mod">
        <div class="item">
          <div class="icon i-1"></div>
          <div class="name">
            卸货<br />
            拆箱
          </div>
          <div class="pic">
            <img src="@/assets/img/website/related-1.png" />
            <img src="@/assets/img/website/related-2.png" />
            <img src="@/assets/img/website/related-3.png" />
          </div>
        </div>
        <div class="item">
          <div class="icon i-2"></div>
          <div class="name">
            搬运<br />
            无尘室搬入
          </div>
          <div class="pic">
            <img src="@/assets/img/website/related-4.png" />
            <img src="@/assets/img/website/related-5.png" />
            <img src="@/assets/img/website/related-6.png" />
          </div>
        </div>
        <div class="item">
          <div class="icon i-3"></div>
          <div class="name">
            吊装
          </div>
          <div class="pic">
            <img src="@/assets/img/website/related-7.png" />
            <img src="@/assets/img/website/related-8.png" />
            <img src="@/assets/img/website/related-9.png" />
          </div>
        </div>
        <div class="item">
          <div class="icon i-4"></div>
          <div class="name">
            空运
          </div>
          <div class="pic">
            <img src="@/assets/img/website/related-10.png" />
            <img src="@/assets/img/website/related-11.png" />
            <img src="@/assets/img/website/related-12.png" />
          </div>
        </div>
      </div>
    </div>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'service-related',
  components: {

  },

  data () {
    return {

    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-service-related {  
  .related-mod {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 63px;
      top: 30px;
      z-index: 1;
      width: 0;
      height: 736px;
      border-left: 4px dashed #d3edfb;
    }
    .item {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 44px;
      .icon {
        flex: none;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
        background-color: #f7f8f8;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 110px;
        &.i-1 {
          background-image: url("../../assets/img/website/related-icon-1.png");
        }
        &.i-2 {
          background-image: url("../../assets/img/website/related-icon-2.png");
        }
        &.i-3 {
          background-image: url("../../assets/img/website/related-icon-3.png");
        }
        &.i-4 {
          background-image: url("../../assets/img/website/related-icon-4.png");
        }
      }
      .name {
        flex: none;
        width: 120px;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        color: #000;
      }
      .pic {
        flex: none;
        width: 840px;
        font-size: 0;
        img {
          width: 274px;
          height: 166px;
          margin-left: 6px;
        }
      }
    }
  }  
}
</style>
